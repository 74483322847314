body {
  background: hsl(206deg 80% 84%);
  font-size: 1.25rem;
}

a {
   color: #b41c6b;
}

a:hover {
  color: #db62a1;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

select {
  margin-top: 30px;
  margin-left: 10px;
}

button {
  margin-top: 20px;
  border-radius: 8px;
  background-color: #d8a3d3;
  padding: 10px;
  font-size: 1.25rem;
  font-weight: 800;
  cursor: pointer;
}

button:hover {
  background-color: #e6b2e1;
}

button:active {
  background-color: #e7cbe4;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  margin-top: 40px;
  width: 70%;
}

@keyframes move {
  from {
    transform: translate(-100%, 50%);
    opacity: 1;
  }
  to {
    transform: translate(100%, -50%);
    opacity: 0.5;
  }
}

.app {
  padding: 20px;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.sin-and-penance {
  height: 230px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.penance {
  background-color: antiquewhite;
  padding: 20px;
  border: black 1px solid;
}

.campfire {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.ground {
  background-color: #2fa52ad1;
  height: 20px;
  width: 200px;
}

.red-1 {
  background-color: hsl(0deg, 100%, 50%);
  animation: move 2000ms;
  animation-iteration-count: infinite;
  height: 10px;
  width: 10px;
}

.red-2 {
  background-color: hsl(360deg, 94%, 50%);
  animation: move 550ms;
  animation-iteration-count: infinite;
  height: 10px;
  width: 10px;
}

.red-3 {
  background-color: hsl(360deg, 50%, 60%);
  height: 10px;
  width: 10px;
  animation: move 1500ms;
  animation-iteration-count: infinite;
}

.orange-1 {
  background-color: #ec8b2c;
  height: 10px;
  width: 10px;
  animation: move 1000ms;
  animation-iteration-count: infinite;
}

.yellow-1 {
  background-color: hsl(62deg, 91%, 68%);
  height: 10px;
  width: 10px;
  animation: move 250ms;
  animation-iteration-count: infinite;
}

.wood-1 {
  background-color: hsl(19deg, 74%, 32%);
  height: 15px;
  width: 60px;
  transform: rotate(-10deg) translateY(10px);
}

.wood-2 {
  background-color: hsl(19deg, 74%, 40%);
  height: 10px;
  width: 60px;
  transform: rotate(10deg);
}

@media (max-width: 780px) {
  .penance {
    padding: 10px;
    font-size: 1rem;
  }

  footer {
    width: 96%;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}